import { useRouter } from 'next/router';
import styles from './PreviewCookieBanner.module.scss';

export const PreviewCookieBanner = () => {
  const router = useRouter();

  const onClick = () => {
    // send a request to the preview API to exit preview mode and redirect back to the current page
    const pathWithoutQuery = router.asPath.split('?')[0] || '/';
    window.location.href = `/api/exit-preview?path=${pathWithoutQuery}`;
  };

  return router.isPreview ? (
    <div className={styles.previewCookieBanner}>
      <div>
        <p>You are viewing a preview of this page.</p>
        <button className={styles.previewCookieBannerButton} onClick={onClick}>
          Exit preview mode
        </button>
      </div>
    </div>
  ) : null;
};
