import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { storeLocale } from '@store-constants';
import { useGetChProductHandlesQuery } from 'lib/contentful/__generated__/ChProductHandles';
import type { ProductQueryVariables } from 'lib/contentful/__generated__/ProductQuery';
import { useProductQuery } from 'lib/contentful/__generated__/ProductQuery';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import { usePreviewCookie } from 'utils/getPreviewCookie';
import { useGetDeepestHandle } from 'utils/hooks/useGetDeepestHandle';

export const useProductLocaleSwitcher = () => {
  const router = useRouter();
  const contentfulHandle = useGetDeepestHandle();
  const preview = usePreviewCookie();
  const locale = storeLocale(router.locale || router.defaultLocale);

  const contentfulProductQueryVariables: ProductQueryVariables = {
    handle: contentfulHandle,
    locale,
    preview,
  };

  const { data: productContentfulId } = useQuery(
    useProductQuery.getKey(contentfulProductQueryVariables),
    useProductQuery.fetcher(
      contentfulQueryDataSource({ byPassCache: preview }),
      contentfulProductQueryVariables
    ),
    {
      select: (data) => {
        return data.productCollection?.items[0]?.sys.id;
      },
    }
  );

  const { data: handleByLanguage } = useGetChProductHandlesQuery(
    contentfulQueryDataSource({ byPassCache: preview }),
    {
      id: productContentfulId || '',
      preview,
    },
    {
      enabled: !!productContentfulId,
      select: (data) => {
        return Object.entries(data).reduce((acc, [key, val]) => {
          acc[key] = (val as { handle?: string })?.handle || router.asPath;
          return acc;
        }, {} as Record<string, string>);
      },
    }
  );

  return handleByLanguage || router.asPath;
};
