import { t } from '@lingui/macro';
import clsx from 'clsx';

import IconFacebook from '../../../icons/social/facebook.svg';
import IconInstagram from '../../../icons/social/instagram.svg';
import IconPinterest from '../../../icons/social/pinterest.svg';
import IconTiktok from '../../../icons/social/tiktok.svg';
import IconTwitter from '../../../icons/social/twitter.svg';
import IconYoutube from '../../../icons/social/youtube.svg';

import styles from './SocialLinks.module.scss';

export type SocialType =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'youtube'
  | 'tiktok'
  | 'pinterest';

export type SocialLink = {
  type: SocialType;
  link: string;
  newTab: boolean;
};

export type SocialLinksProps = {
  links: SocialLink[];
  className?: string;
};

/**
 * Social Link List Component
 * @param links - SocialLinksProps
 */
export const SocialLinks = ({ links, className }: SocialLinksProps) => {
  return (
    <ul
      className={clsx(
        styles.socialLinks,
        links.length < 4 ? styles.socialLinks__short : '',
        className
      )}
    >
      {links.map((item) => {
        return (
          <li key={item.type} className={styles.socialLinks__listItem}>
            <a
              href={item.link}
              target={item.newTab ? '_blank' : undefined}
              rel="noreferrer"
              aria-label={getSocialLinkAriaLabel(item.type)}
              data-testid={`link-${item.type}`}
            >
              <SocialIcon type={item.type} />
              <span className="visually-hidden">{item.type}</span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

/**
 *
 * Getting social link Aria Label
 *
 * @param type SocialType
 * @returns Aria Label String
 */
const getSocialLinkAriaLabel = (type: SocialType) => {
  switch (type) {
    case 'facebook':
      return t({
        id: 'socialLinks.button.facebook',
        message: 'Facebook link, opens a new tab',
      });
    case 'twitter':
      return t({
        id: 'socialLinks.button.twitter',
        message: 'Twitter link, opens a new tab',
      });
    case 'instagram':
      return t({
        id: 'socialLinks.button.instagram',
        message: 'Instagram link, opens a new tab',
      });
    case 'youtube':
      return t({
        id: 'socialLinks.button.youtube',
        message: 'Youtube link, opens a new tab',
      });
    case 'pinterest':
      return t({
        id: 'socialLinks.button.pinterest',
        message: 'Pinterest link, opens a new tab',
      });
    case 'tiktok':
      return t({
        id: 'socialLinks.button.tiktok',
        message: 'Tiktok link, opens a new tab',
      });
  }
};

/**
 * Social Icon Component
 *
 * @remarks
 * Matches SocialType to correct SVG icon
 *
 * @param type - SocialType
 */
const SocialIcon = ({ type }: { type: SocialType }) => {
  const className = `icon__${type}`;

  switch (type) {
    case 'facebook':
      return (
        <IconFacebook
          className={clsx(className, styles.socialLinks__facebook)}
        />
      );
    case 'twitter':
      return (
        <IconTwitter className={clsx(className, styles.socialLinks__twitter)} />
      );
    case 'instagram':
      return (
        <IconInstagram
          className={clsx(className, styles.socialLinks__instagram)}
        />
      );
    case 'youtube':
      return (
        <IconYoutube className={clsx(className, styles.socialLinks__youtube)} />
      );
    case 'pinterest':
      return (
        <IconPinterest
          className={clsx(className, styles.socialLinks__pinterest)}
        />
      );
    case 'tiktok':
      return (
        <IconTiktok className={clsx(className, styles.socialLinks__tiktok)} />
      );
  }
};

export default SocialLinks;
